import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "src/components/core/layout/layout"
import Img from "gatsby-image/withIEPolyfill"
import Card from "src/components/core/card"
import CardContent from "src/components/core/card-content"
import styled from "styled-components"
import BodyImage from "src/components/core/body-image"
import Spacer from "src/components/core/spacer"
import PostsIndex from "src/components/posts/posts-index"
const Title = styled.h1`
  font-weight: normal;
  font-size: 3rem;
  margin: 0.5rem 0;
  font-family: "Crimson Text", "serif";
`

const Subtitle = styled.h3`
  font-weight: normal;
  font-style: italic;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 1rem;
`

const PostDate = styled.h3`
  font-weight: normal;
  font-style: italic;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
`

const CoverWrapper = styled.div`
  /* width: ${({ coverstyle }) =>
    coverstyle == "fullwidth" ? "100%" : "auto"};
  height: ${({ coverdimension, coverstyle }) =>
    coverstyle == "fullheight" ? coverdimension + "rem" : "auto"}; */
    ${({ coverstyle, coverdimension }) => {
      if (coverstyle === "fullwidth") {
        return `
            width: 100%;
            margin: 0;
            `
      } else if (coverstyle == "setheight") {
        return `
            max-width: 100%;
            width: auto;
            height: ${coverdimension}rem;
            margin: auto;
            `
      } else if (coverstyle == "setwidth") {
        return `
            max-width: 100%;
            height: auto;
            width: ${coverdimension}rem;
            margin: auto;
            `
      }
    }}
  .gatsby-image-wrapper {
    height: calc(100% - 2rem);
    width: 100%;
  }
`

const CoverCaption = styled.p`
  font-style: italic;
  text-align: center;
  margin-bottom: 2rem;
`

const ToggleBtn = styled.p`
  margin-top: -1rem;
  cursor: pointer;
  &:hover {
    color: #d04c1a;
  }
  margin-bottom: ${props => (props.open === true ? "0" : "2rem")};
`

const MorePostsWrapper = styled.div`
  max-height: ${props => (props.open === true ? "auto" : 0)};
  max-width: ${props => (props.open === true ? "auto" : 0)};
  height: auto;
  transition: all 0.5s;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  a {
    margin: 1rem;
  }
`
const BodyText = styled.div`
  line-height: 2.1rem;
  p {
    @media screen and (min-width: 1200px) {
      text-align: justify;
      padding: 0 3.5rem;
    }
  }
  ul{
      padding: 0 1rem;
    @media screen and (min-width: 1200px) {
      text-align: justify;
      padding: 0 4.5rem;
    }
  }
  .block-img {
    text-align: center;
    img {
      margin: auto;
    }
  }
`
const MoreText = styled.p``

const Post = ({ data, className, coverstyle, coverdimension }) => {
    const [open, setOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }
  useEffect(() => {
      setOpen(false)
      setLoaded(true)
  }, [])
  const postData = data.prismicPost.data
  const tags = data.allPrismicTag.nodes
  const allPosts = data.allPrismicPost.nodes
  const relatedPosts = []
  postData.tags.forEach(tag => {
    const relatedPostsPartial = allPosts.filter(node => {
      return node.data.tags.some(postTag => {
        return (
          tag.tag &&
          postTag.tag &&
          postTag.tag.document[0].prismicId === tag.tag.document[0].prismicId &&
          node.prismicId !== data.prismicPost.prismicId
        )
      })
    })
    if (relatedPostsPartial.length > 0) relatedPosts.push(relatedPostsPartial)
  })
  const flatRelatedPosts = relatedPosts.length
    ? relatedPosts.reduce((acc, val) => acc.concat(val), [])
    : null
  const uniqueRelatedPosts = flatRelatedPosts
    ? flatRelatedPosts.filter((item, index) => {
        return flatRelatedPosts.indexOf(item) === index
      })
    : null
    return !loaded ? <Layout></Layout> : (
      <Layout
      title = { postData.title ? postData.title.text : null }
      description = { postData.body && postData.body[0] && postData.body[0].text ? postData.body[0].text.text.slice(0, 80) + " ..." : null }
      sidebarimage = {
        postData.sidebarimage &&
        postData.sidebarimage.localFile &&
        postData.sidebarimage.localFile.childImageSharp
          ? postData.sidebarimage.localFile.childImageSharp.fluid
          : null
      }
      seoImage = { postData.cover.thumb ? postData.cover.thumb.url : null }
      sidebar = { postData.sidebar.html }
      relatedPosts = { uniqueRelatedPosts }
    >
      <Spacer />
      <Card>
        <CardContent>
          <Title>{postData.title.text}</Title>
          <Subtitle>
            {postData.subtitle ? postData.subtitle.text : null}
          </Subtitle>
          <PostDate>{postData.date ? postData.date : data.prismicPost.first_publication_date}</PostDate>
        </CardContent>
        <CoverWrapper
          className={className}
          coverstyle={postData.coverstyle ? postData.coverstyle : "setheight"}
          coverdimension={
            postData.coverdimension ? postData.coverdimension : "20rem"
          }
        >
          {postData.cover.localFile &&
          postData.cover.localFile.childImageSharp ? (
            <Img
              fluid={postData.cover.localFile.childImageSharp.fluid}
              objectFit="contain"
            />
          ) : null}
        </CoverWrapper>
        {postData.covercaption ? (
          <CoverCaption>{postData.covercaption.text}</CoverCaption>
        ) : null}
        {postData.body.map(bodyPart => (
          <CardContent>
            <BodyText
              dangerouslySetInnerHTML={{ __html: bodyPart.text.html }}
            />
            {bodyPart.image.localFile &&
            bodyPart.image.localFile.childImageSharp ? (
              <BodyImage
                image={bodyPart.image.localFile.childImageSharp.fluid}
                imagestyle={
                  bodyPart.imagestyle ? bodyPart.imagestyle : "fullheight"
                }
                imagedimension={
                  bodyPart.imagedimension ? bodyPart.imagedimension : "10rem"
                }
                caption={
                  bodyPart.image_caption ? bodyPart.image_caption.text : null
                }
              />
            ) : null}
          </CardContent>
        ))}
      </Card>
      {uniqueRelatedPosts ? (
        <>
          <MoreText>Some related posts:</MoreText>
          <PostsIndex fullwidth={true} posts={uniqueRelatedPosts.slice(0, 3)} />

          {uniqueRelatedPosts.length > 3 ? (
            <>
              <ToggleBtn open={open} onClick={toggleOpen}>
                {open ? "less" : "more related posts"}
              </ToggleBtn>
              <MorePostsWrapper open={open}>
                <PostsIndex
                  fullwidth={true}
                  posts={uniqueRelatedPosts.slice(4)}
                />
              </MorePostsWrapper>
            </>
          ) : null}
        </>
      ) : null}
    </Layout>
  )
}
export default Post
export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date(formatString: "D MMMM, YYYY")
      prismicId
      data {
        date(formatString: "D MMMM, YYYY")
        sidebar {
          html
        }
        title {
          text
        }
        subtitle {
          text
        }
        coverdimension
        coverstyle
        covercaption {
          html
          text
        }
        sidebarimage {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        cover {
            thumb {
                url
              }
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        body {
          text {
            html
            text
          }

          imagedimension
          imagestyle
          image_caption {
            html
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }

        tags {
          tag {
            document {
              data {
                tag
              }
              prismicId
            }
          }
        }
      }
      prismicId
    }

    allPrismicTag {
      nodes {
        data {
          tag
        }
        prismicId
      }
    }
    allPrismicPost {
      nodes {
        uid
        prismicId
        data {
          subtitle {
            text
          }
          title {
            text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          abstract {
            html
          }
          previewcover {
            md {
              url
            }
            sm {
              url
            }
          }
          body {
            text {
              html
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          tags {
            tag {
              document {
                prismicId
              }
            }
          }
          date(formatString: "D MMMM, YYYY")
        }
        first_publication_date(formatString: "D MMMM, YYYY")
      }
    }
  }
`
